import React, { forwardRef } from 'react';
import FormMessage from '../form-message';
import './dropdown.scss';

export interface DropdownProps {
  label: string;
  id?: string;
  disabled?: boolean;
  options: Array<{ id: string; value: any; label: string; selected?: boolean }>;
  selectedValue?: any;

  leftIcon?: string;
  message?: {
    type: 'error' | 'info' | 'warning' | 'success';
    description: string;
  };
  headerText?: string;
  className?: string;
  children: React.ReactNode;
  onSelected: (value: any) => void;
  onBlur?: (event?: React.FocusEvent<HTMLSelectElement>) => void;
  [key: string]: any;
}
const Dropdown = (
  {
    label,
    disabled,
    message,
    children,
    selectedValue = '',
    leftIcon = '',
    headerText,
    className,
    onSelected,
    onBlur,
    id,
    ...props
  }: DropdownProps,
  ref: React.Ref<HTMLSelectElement>,
): JSX.Element => {
  const classes = ['form-dropdown-select', className, selectedValue ? '' : 'form-dropdown-selectnone'].filter(Boolean).join(' ');
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelected && onSelected(event);
  };
  const handleBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
    onBlur && onBlur(event);
  };
  return (
    <div className={`form-dropdown${leftIcon ? ' dropdown-with-lefticon' : ''}${message?.type ? ' is-error' : ''}`}>
      <select
        className={classes}
        id={id || 'ac-dropdown'}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selectedValue}
        aria-labelledby="ac-dropdown-label"
        aria-invalid={message?.type === 'error'}
        aria-describedby={`com.apple.sse.grl:grlui-core:jar:1.2.7-SNAPSHOT_error`}
        disabled={disabled}
        ref={ref}
        {...props}>
        {headerText && (
          <option disabled value="">
            {headerText}
          </option>
        )}
        {children}
      </select>
      <span className="form-dropdown-chevron" aria-hidden="true" />
      <span className="form-dropdown-label" id="ac-dropdown-label" aria-hidden="true">
        {label}
      </span>
      {leftIcon && (
        <div className="form-dropdown-lefticon form-icons-focusable">
          <span className={`form-icons ${leftIcon}`}></span>
        </div>
      )}
      {message && message.type === 'error' ? (
        <FormMessage id={`com.apple.sse.grl:grlui-core:jar:1.2.7-SNAPSHOT_error`} description={message.description} type={message.type} />
      ) : null}
    </div>
  );
};

export default forwardRef(Dropdown);
